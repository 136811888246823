<template>
  <el-badge
    :value="selectionValue"
    :hidden="!selectionValue"
    type="primary"
    class="item"
  >
    <el-popover
      placement="bottom"
      trigger="click"
      @show="saveState"
      @hide="apply"
    >
      <div class="items-container">
        <el-input
          ref="quickFilter"
          v-model="quickFilter"
          :placeholder="placeholder ? placeholder : `Find ${name}...`"
          prefix-icon="el-icon-search"
          size="mini"
          clearable
          @keyup.native.esc="quickFilter = ''"
        />
        <el-checkbox-group
          v-if="hasFilteredItemsCombined || text"
          v-model="selectedItems"
          class="filter-items"
        >
          <el-checkbox
            v-if="!noEmpty"
            key="withoutItem"
            :label="null"
          >
            No {{ name }}
          </el-checkbox>
          <template v-if="!text">
            <template v-for="(group, index) in itemGroups">
              <template v-if="group.items.length">
                <div
                  v-if="group.items.length"
                  :key="group.name"
                  class="item-list-header"
                >
                  <div class="item-name">
                    {{ group.name }}
                  </div>
                  <span
                    v-if="!index"
                    class="bulk-action"
                    @click="selectAll"
                  >
                    All
                  </span>
                  <span
                    v-if="!index"
                    class="bulk-action"
                    @click="selectNone"
                  >
                    None
                  </span>
                </div>
                <el-checkbox
                  v-for="item in group.items"
                  :key="item.uuid"
                  :label="item.uuid"
                >
                  {{ item.name }}
                </el-checkbox>
              </template>
            </template>
          </template>
        </el-checkbox-group>
        <div
          v-else
          class="no-results"
        >
          No items
        </div>
      </div>
      <el-button
        slot="reference"
        :icon="icon"
        :type="selectedItems.length ? 'primary' : 'info'"
        size="mini"
        plain
        round
      >
        {{ name }}
      </el-button>
    </el-popover>
  </el-badge>
</template>

<script>
export default {
  name: 'FilterButton',

  props: {
    items: {
      type: [Array, Object],
      required: false,
      default: () => [],
    },
    defaultItems: {
      type: [Array, Object],
      required: false,
      default: () => [],
    },
    name: {
      type: String,
      required: false,
      default: () => '',
    },
    icon: {
      type: String,
      required: true,
      default: () => 'el-icon-news',
    },
    text: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    noEmpty: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => '',
    },
  },

  data: () => ({
    selectedItems: [],
    savedSelectedItems: [],
    quickFilter: '',
  }),

  computed: {
    hasFilteredItemsCombined() {
      return this.itemGroups.some(group => group.items.length);
    },
    itemGroups() {
      return Array.isArray(this.items)
        ? [
          {
            name: this.name,
            items: this.getFilteredItems(this.name),
          },
        ]
        : Object.keys(this.items).map(name => ({ name, items: this.getFilteredItems(name) }));
    },
    selectionValue() {
      if (this.text && this.quickFilter.trim()) {
        return 'abc';
      }

      return this.selectedItems.length;
    },
    isDirty() {
      if (this.text) {
        return this.savedSelectedItems !== this.quickFilter.trim();
      }

      return (this.savedSelectedItems.join() !== this.selectedItems.join())
        || (this.savedSelectedItems.length !== this.selectedItems.length);
    },
  },

  created() {
    if (this.defaultItems.length) {
      this.selectedItems.push(...this.defaultItems);
    }
  },

  methods: {
    getFilteredItems(name) {
      const subject = Array.isArray(this.items) ? this.items : this.items[name];

      return this.quickFilter.trim() && !this.text
        ? subject.filter(
          item => item.name
            .toLowerCase()
            .includes(this.quickFilter.trim().toLowerCase()),
        )
        : subject;
    },
    selectAll() {
      this.selectedItems = this.itemGroups
        .reduce((acc, cur) => [...acc, ...cur.items], [])
        .map(items => items.uuid);
    },
    selectNone() {
      this.selectedItems = [];
    },
    saveState() {
      this.$nextTick(() => {
        this.$refs.quickFilter.$el.querySelector('input').focus();
      });

      if (this.text) {
        this.savedSelectedItems = this.quickFilter.trim();

        return;
      }
      this.savedSelectedItems = [...this.selectedItems];
    },
    apply() {
      if (!this.isDirty) {
        return;
      }

      if (this.selectedItems && this.selectedItems[0] === null) {
        this.$emit('apply', null);

        return;
      }

      this.$emit('apply', this.text ? this.quickFilter.trim() : this.selectedItems);
    },
  },
};
</script>

<style scoped>
  .filter-items {
    display     : grid;
    line-height : 26px;
    margin      : 15px 0;
  }

  .item-name {
    color : #909399;
  }

  .item-list-header {
    display               : grid;
    grid-template-columns : auto max-content max-content;
    grid-gap              : 7px;
    font-size             : small;
    text-transform        : uppercase;
    margin                : 10px 0 5px;
  }

  .bulk-action {
    color  : #409EFF;
    cursor : pointer;
  }

  .bulk-action:hover {
    color : #909399;
  }

  .no-results {
    padding    : 20px;
    text-align : center;
    color      : #aeb1b5;
  }
</style>
